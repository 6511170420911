import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import { AnalyticsCard } from './AnalyticsCard';
import { TopProducts } from './TopProducts';
import { RangeSelect } from '../shared/RangeSelect';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onResolveVendorProductsReport } from '../../graphql/subscriptions';
import * as API from '../../lib/manualApi';
import { ReportProduct } from '../../lib/api';

import { endOfToday, subDays } from 'date-fns'

export function Dashboard(props: DashboardProps) {
    const [products, setProducts] = useState<Array<ReportProduct>>([]);
    const [loading, setLoading] = useState(true);

    const fetchProductsReport = async (vendorID: string, from: Date, to: Date) => {
        setLoading(true);

        const queryArgs = {
            vendorID,
            fromDate: from.toISOString(),
            toDate: to.toISOString()
        }

        try {
            const productsReportResult = await API.getVendorProductsReport(queryArgs);
            console.log('GOT PRODUCTS REPORT ID:', productsReportResult);

            if (productsReportResult) {
                const timer = setTimeout(() => {
                    setLoading(false);
                    onResolveProductsReportSubscription.unsubscribe();
                }, 240000);

                let items: any[] = [];

                // @ts-ignore - for some reason it doesn't like the .subscribe.... 
                const onResolveProductsReportSubscription = AmplifyAPI.graphql(graphqlOperation(onResolveVendorProductsReport, { id: productsReportResult })).subscribe({
                    next: (data) => {
                        let reportResult = data.value.data.onResolveVendorProductsReport;
                        items = items.concat(reportResult.items);

                        if (!reportResult.nextToken) {
                            clearTimeout(timer);
                            setProducts(items);
                            onResolveProductsReportSubscription.unsubscribe();
                            setLoading(false);
                        }
                    },
                    error: (error) => {
                        console.error(error);
                        setLoading(false);
                    }
                });
            } else {
                setLoading(false);
            }

        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    }


    useEffect(() => {
        if (!props.vendorID || props.vendorID === '') return;

        let from = subDays(new Date(), 7);
        let to = endOfToday();

        fetchProductsReport(props.vendorID, from, to);
    }, [props.vendorID])

    const handleSelectChange = async (fromDate: Date, toDate: Date) => {
        await fetchProductsReport(props.vendorID, fromDate, toDate);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item container direction="row" spacing={2}>
                    <RangeSelect handleSelectChange={handleSelectChange} onApplyClicked={handleSelectChange} />
                    {
                        loading &&
                        <CircularProgress />
                    }
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={3}>
                        <AnalyticsCard products={products} />
                    </Grid>
                    <Grid item xs>
                        <TopProducts products={products} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export interface DashboardProps {
    authData: any
    vendorID: string
}