import React from 'react';
import { Grid, Paper, Popover, Button } from '@material-ui/core'

import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core'

import { VendorProduct, useListVendorProductsQuery } from '../../lib/api';

import { format } from 'date-fns';

const formatString = 'MMM dd yyyy';

export function AssignedProducts(props: AssignedProductsProps) {
    const queryArgs = { vendorID: props.vendorID };
    const { data } = useListVendorProductsQuery<Array<VendorProduct>, Error>(queryArgs, {
        enabled: !!props.vendorID,
        select: (response) => { return response.listVendorProducts?.items ?? [] }
    });

    return (
        <Grid container spacing={2}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Image</TableCell>
                            <TableCell align="left">Brand</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Approved</TableCell>
                            <TableCell align="left">Enabled</TableCell>
                            <TableCell align="left">Owned</TableCell>
                            <TableCell align="left">Assigned</TableCell>
                            <TableCell align="left">Unassigned</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.map(vendorProduct => {
                                return (
                                    <ProductRow key={vendorProduct.product.id} vendorProduct={vendorProduct} />
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export interface AssignedProductsProps {
    authData: any
    vendorID: string
}

function ProductRow(props: ProductRowProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleImageClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleImageClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? props.vendorProduct.product.name : undefined;

    return (
        <TableRow key={props.vendorProduct.product.id}>
            <TableCell align="center">
                <Button onClick={handleImageClick}>
                    <img src={props.vendorProduct.product.mainPhotoURL || ''}
                        height="100px"
                        alt="small product" />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleImageClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <img src={props.vendorProduct.product.mainPhotoURL || ''}
                        height="400"
                        alt="large product" />
                </Popover>

            </TableCell>
            <TableCell>{props.vendorProduct.product.brand}</TableCell>
            <TableCell>{props.vendorProduct.product.name}</TableCell>
            <TableCell>{props.vendorProduct.product.approved.toString()}</TableCell>
            <TableCell>{props.vendorProduct.product.active.toString()}</TableCell>
            <TableCell>{props.vendorProduct.owned.toString()}</TableCell>
            <TableCell>
                {format(new Date(props.vendorProduct.assigned), formatString)}
            </TableCell>
            <TableCell>
                {
                    props.vendorProduct.unassigned 
                    ? format(new Date(props.vendorProduct.unassigned), formatString)
                    : ''
                }
            </TableCell>
        </TableRow>
    )
}

interface ProductRowProps {
    vendorProduct: VendorProduct
}