const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://qn2yj3dt5nch7bw2dbzxx4bhrm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "Auth": {
        "region": "us-east-2",
        "userPoolId": "us-east-2_zYsecdS8p",
        "userPoolWebClientId": "365kt8hhpjsf0dfdc3spop37pg",
        "mandatorySignIn": true,
        "cookiestorage": {
            "domain": ".getvoxi.com",
            "expires": 30,
            "secure": true
        },
        "authenticationFlowType": "USER_PASSWORD_AUTH"
    },
    "aws_user_pools_id": "",
    "aws_user_pools_web_client_id": "",
    "aws_cognito_identity_pool_id": "us-east-2:25a6ecca-0722-4b5d-bcdf-831ee24ec0a7",
    "aws_cognito_region": "us-east-2",
    "aws_mobile_analytics_app_id": "",
    "aws_mobile_analytics_app_region": "us-east-1"
}
    
export default awsmobile;