import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib/types';

export function amplifyFetcher<TData, TVariables>(query: string, variables?: TVariables) {
    return async (): Promise<TData> => {
        const operation = {
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            ...graphqlOperation(query, variables)
        }

        const response = await API.graphql(operation) as GraphQLResult<any>;
        return response.data;
    }
}