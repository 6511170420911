import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Popover, Button, Typography, IconButton, Tooltip, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import RefreshIcon from '@material-ui/icons/Refresh';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
// import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { AvailableProduct, useListAvailableProductsQuery } from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function AvailableProducts(props: AvailableProductsProps) {
    const classes = useStyles();
    const history = useHistory();
    const [customerNames, setCustomerNames] = useState<any>({});

    const { data, isLoading, refetch } = useListAvailableProductsQuery<Array<AvailableProduct>, Error>({}, {
        select: (response) => { return response.listAvailableProducts?.items ?? [] },
        onSuccess: (data) => {
            const names = data.map(product => product.customerName);
            const customerNamesSet = new Set(names.sort());

            let entries: any = {}
            customerNamesSet.forEach((name, index) => {
                entries[index] = name
            });

            setCustomerNames(entries);
        }
    });

    const renderTableImage = (rowData) => {
        // return (
        //     <img src={rowData.product.mainPhotoURL} width="50" height="50" alt={rowData.product.name} />
        // )

        return (
            <ProductImage availableProduct={rowData} />
        )
    }

    const refreshProductList = async () => {
        refetch()
    }

    const editProduct = (event, rowData) => {
        history.push(`/products/available/${rowData.id}`)
    }

    const tableTitle = (
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
                <Typography variant="h5">Available Products</Typography>
            </Grid>
            <Grid item>
                <Tooltip title="Refresh product list">
                    <IconButton onClick={refreshProductList}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

    return (
        <Grid container spacing={2}>
            <Grid item>
                <MaterialTable
                    title={tableTitle}
                    icons={tableIcons}
                    columns={[
                        { title: "Image", field: "product.mainPhotoURL", export: false, filtering: false, render: renderTableImage },
                        { title: "Owner", field: "customerName", lookup: customerNames },
                        { title: "Brand", field: "product.brand" },
                        { title: "Name", field: "product.name" },
                        { title: "Approved", field: "product.approved", type: "boolean" },
                        { title: "Enabled", field: "product.active", type: "boolean" },
                        { title: "Is Replacement", field: "product.replacementProduct", type: "boolean" }
                    ]}
                    data={data ?? []}
                    components={{
                        Toolbar: props => (
                            <div className={classes.tableToolBar}>
                                <MTableToolbar {...props} className={classes.tableToolBar} />
                            </div>
                        )
                    }}
                    options={{
                        exportButton: true,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100],
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                // actions={[
                //     { icon: (() => (<Edit />)), tooltip: 'Edit Product', onClick: editProduct }
                // ]}
                />
            </Grid>
        </Grid>
    )
}

export interface AvailableProductsProps {
    authData: any
}

function ProductImage(props: ProductImageProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleImageClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleImageClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? props.availableProduct.product.name : undefined;

    return (
        <>
            <Button onClick={handleImageClick}>
                <img src={props.availableProduct.product.mainPhotoURL || ''}
                    height="50px"
                    alt="small product" />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleImageClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <img src={props.availableProduct.product.mainPhotoURL || ''} height="300" alt="large product" />
            </Popover>
        </>
    )
}

interface ProductImageProps {
    availableProduct: AvailableProduct
}