import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReportProduct } from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
}));

export function AnalyticsCard(props: AnalyticsCardProps) {
    const classes = useStyles();
    const [impressions, setImpressions] = useState(0);
    const [views, setViews] = useState(0);
    const [clicks, setClicks] = useState(0);

    const calculateViews = (items) => {
        let calculatedImpressions = 0;
        let calculatedViews = 0;
        let calculatedClicks = 0;

        items.forEach(product => {
            calculatedImpressions += product.imagePreviews;
            calculatedViews += product.detailViews;
            calculatedClicks += product.clicks;
        })

        setImpressions(calculatedImpressions);
        setViews(calculatedViews);
        setClicks(calculatedClicks);
    }

    useEffect(() => {
        calculateViews(props.products)
    }, [props])

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Typography variant="h5">Analytics</Typography>
                    <br />
                </Grid>
                <Grid item>
                    <Typography variant="body2">Impressions: {impressions}</Typography>
                    <Typography variant="body2">Views: {views}</Typography>
                    <Typography variant="body2">Clicks: {clicks}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export interface AnalyticsCardProps {
    products: Array<ReportProduct>
}