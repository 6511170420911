import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { 
    AppBar, Avatar, Box, Button, Drawer, Hidden, IconButton, List, ListItem, ListItemText,
    Menu, MenuItem, Toolbar, Typography, Select 
} from '@material-ui/core';

import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconBarChart from '@material-ui/icons/BarChart'

import { isVoxiAdmin } from '../../lib/util';

import { SideBarItem } from './SideBarItem';
import { Dashboard } from '../dashboard/Dashboard';
import { Profile } from '../profile/Profile';
import { AssignedProducts, AvailableProducts } from "../products";
import { ProductsReport } from "../reports/ProductsReport";

import { Maybe, Vendor, useListVendorsQuery, useGetVendorQuery } from '../../lib/api';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        }
    },
    vendorSelect: {
        color: 'black',
        backgroundColor: 'white',
        textAlign: 'center',
        maxHeight: '40px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        ...theme.mixins.toolbar,
    },
    topBar: {
        // @ts-ignore
        backgroundColor: theme.palette.toolbar.dark
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
}));

const initialState = {
    vendors: [],
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchVendorsSuccess':
            return {
                ...state,
                vendors: action.vendors,
                loading: false
            }
        default:
            throw new Error();
    }
}

function ResponsiveDrawer(props: any) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [drawerState, dispatch] = useReducer(reducer, initialState)
    const [selectedVendor, setSelectedVendor] = useState('');
    const [authData, setAuthData] = useState(props.authData);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useListVendorsQuery<Array<Maybe<Vendor>>, Error>({}, {
        enabled: isSuperAdmin,
        select: (response) => { return response.listVendors?.items ?? [] },
        onSuccess: (data) => {
            dispatch({ type: 'fetchVendorsSuccess', vendors: data })
            const vendor = data[0];
            if (vendor) {
                setSelectedVendor(vendor.id);
            }
        },        
        refetchInterval: false,
        refetchOnMount: true
    })

    const vendorQueryArgs = { id: props.authData.signInUserSession?.idToken?.payload['vendorID'] }
    useGetVendorQuery<Maybe<Vendor>, Error>(vendorQueryArgs, {
        enabled: !!props.authData.signInUserSession?.idToken?.payload
            && !!props.authData.signInUserSession?.idToken.payload['vendorID']
            && !isSuperAdmin,
        select: (response) => { return response.getVendor },
        onSuccess: (data) => {
            if (!data) return;

            dispatch({ type: 'fetchVendorsSuccess', vendors: [data] });
            setSelectedVendor(data.id)
        },
        refetchInterval: false,
        refetchOnMount: true
    })

    useEffect(() => {
        if (!props.authData) return;

        setAuthData(props.authData);

        let idToken = props.authData.signInUserSession?.idToken;
        let groups = idToken?.payload['cognito:groups'];
        let isSuperAdmin = isVoxiAdmin(groups);
        setIsSuperAdmin(isSuperAdmin);
    }, [props.authData])

    const onAvatarClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        props.onSignOut();
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleVendorSelectChange = async (event) => {
        setSelectedVendor(event.target.value);
    }

    const menuItems = [
        {
            key: 'dashboard',
            name: 'Dashboard',
            link: '/',
            Icon: IconDashboard
        },
        {
            key: 'products',
            name: 'Products',
            Icon: IconShoppingCart,
            subMenu: [
                {
                    key: 'assignedProducts',
                    name: 'Assigned Products',
                    link: '/products/assigned',
                    padding: '35px'
                },
                {
                    key: 'unclaimedProducts',
                    name: 'Available Products',
                    link: '/products/available',
                    padding: '35px'
                }
            ]
        },

        {
            key: 'reports',
            name: 'Reports',
            link: '/reports',
            Icon: IconBarChart
        }
    ]

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <img src={process.env.PUBLIC_URL + '/GetVoxi_White_140x45.png'} alt="voxi logo" style={{ paddingLeft: "10" }} />
            </div>
            <div>
                <Select id="vendor-select"
                    className={classes.vendorSelect}
                    value={selectedVendor}
                    onChange={handleVendorSelectChange}
                    variant="outlined"
                    fullWidth
                >
                    {
                        drawerState.vendors &&
                        drawerState.vendors.map((vendor: Vendor) => {
                            return (
                                <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </div>
            <List>
                {
                    menuItems.map((item, index) => (
                        <SideBarItem {...item} key={index} />
                    ))
                }
                <ListItem style={{ textAlign: 'center' }}>
                    <ListItemText primary={`Version: ${process.env.REACT_APP_VERSION || '0.0.1'}`} />
                </ListItem>
            </List>
        </div>
    )

    return (
        <div className={classes.root}>
            <Router>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.topBar}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width="100%"
                        >
                            <Typography>{authData.username}</Typography>
                            <Button onClick={onAvatarClick}>
                                <Avatar
                                    alt="profile picture"
                                    src=''
                                />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} component={Link} to={'/profile'}>Profile</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="drawer items">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <main className={classes.content}>
                        <Route exact path="/profile" render={(props: any) => <Profile {...props} authData={authData} />} />
                        <Route exact path="/products/assigned" render={(props: any) => <AssignedProducts {...props} authData={authData} vendorID={selectedVendor} />} />
                        <Route exact path="/products/available" render={(props: any) => <AvailableProducts {...props} authData={authData} />} />
                        <Route exact path="/reports" render={(props: any) => <ProductsReport {...props} authData={authData} vendorID={selectedVendor} />} />
                        <Route exact path="/" render={(props: any) => <Dashboard {...props} authData={authData} vendorID={selectedVendor} />} />
                    </main>
                </main>
            </Router>
        </div>
    );
}

export default ResponsiveDrawer;