import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReportProduct } from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    product: {
        width: '100%'
    },
    paper: {
        padding: theme.spacing(2)
    },
}));

export function TopProducts(props: TopProductsProps) {
    const classes = useStyles();
    const [topProducts, setTopProducts] = useState<Array<ReportProduct>>([]);

    const calculateTopProducts = (products) => {
        products.sort(compareProducts);
        const top = products.slice(0, 10)
        setTopProducts(top)
    }

    useEffect(() => {
        calculateTopProducts(props.products)
    }, [props.products])

    return (
        <Paper className={classes.paper}>
            <Grid container item direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Top Products</Typography>
                </Grid>
                <Grid item className={classes.product}>
                    {
                        topProducts.map(product => {
                            return (
                                <Grid container item spacing={3}>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProductCard key={product.id} product={product} />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export interface TopProductsProps {
    products: Array<ReportProduct>
}

function ProductCard({ product }) {
    return (
        <Grid container item spacing={3}>
            <Grid item>
                <img src={product.mainPhotoURL} width="30" height="50" alt={product.name} />
            </Grid>
            <Grid item xs={12} sm container alignItems="center">
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                        <Typography variant="subtitle1">{product.name}</Typography>
                        <Typography variant="body2">{product.brandName}</Typography>
                    </Grid>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="body1">Impressions</Typography>
                    <Typography variant="body2">{product.imagePreviews}</Typography>
                </Grid>
            </Grid>

        </Grid>
    )
}

function compareProducts(productA, productB) {
    if (productA.imagePreviews > productB.imagePreviews) {
        return -1;
    }

    if (productA.imagePreviews < productB.imagePreviews) {
        return 1;
    }

    return 0;
}