import axios from 'axios';

export async function uploadPresignedFile(presignedUrl, file, options) {
    return await axios.put(presignedUrl, file, options);
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatSeconds(seconds, decimals = 2) {
    if (seconds === 0) return '0 Seconds';

    const k = 60;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Seconds', 'Min', 'Hours'];

    const i = Math.floor(Math.log(seconds) / Math.log(k));

    return parseFloat((seconds / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formattedTime(value, index, values) {
    var hours = ("0" + Math.floor(value / (60 * 60))).slice(-2);
    var divisor_for_minutes = value % (60 * 60);
    var minutes = ("0" + Math.floor(divisor_for_minutes / 60)).slice(-2);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = ("0" + Math.ceil(divisor_for_seconds)).slice(-2);

    let formatted = `${hours}h:${minutes}m:${seconds}s`
    return formatted
}

const VOXI_ADMINS_GROUP_NAME = 'VoxiAdmins';
const VENDORS_GROUP_NAME = 'Vendors';

export function isVoxiAdmin(groups) {
    let groupFound = groups.find(group => group === VOXI_ADMINS_GROUP_NAME)
    return groupFound ? true : false;
}

export function isVendor(groups) {
    let groupFound = groups.find(group => group === VENDORS_GROUP_NAME)
    return groupFound ? true : false;
}