import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api/lib/types';

import {
    Maybe,
    Query,

    QueryGetVendorProductsReportArgs,
    GetVendorProductsReportDocument
} from './api';

// REPORTS
export async function getVendorProductsReport(queryArgs: QueryGetVendorProductsReportArgs): Promise<Maybe<string>> {
    const response = await genericQuery(GetVendorProductsReportDocument, queryArgs);
    return response.data?.getVendorProductsReport;
}

// GENERIC UTILITY
async function genericQuery(query: string, queryArgs: any) {
    return await API.graphql(graphqlOperation(query, queryArgs)) as GraphQLResult<Query>;
}

async function genericQueryFilter(query: string, filterInput: any) {
    return await API.graphql(graphqlOperation(query, { input: filterInput } )) as GraphQLResult<Query>;
}